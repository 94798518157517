import { Component, Input } from '@angular/core';
import { FsColorPaletteSymbols } from '../../fs-controls-shared.model';

@Component({
  selector: 'fs-text-counter',
  templateUrl: './fs-text-counter.component.html',
  styleUrls: ['./fs-text-counter.component.scss'],
})
export class FsTextCounterComponent {
  @Input('text') text: string;
  @Input('limited') limited: number;
  @Input('color') color: FsColorPaletteSymbols;

  public get length(): number {
    return this.text?.length ?? 0;
  }

  constructor() {
    this.text = '';
    this.limited = 0;
    this.color = 'white';
  }
}
