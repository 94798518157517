import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { FsPopupOptions } from '../fs-popups-shared';
import { FsPopupOverlayComponent } from './fs-popup-overlay.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FsPopupOverlayService {
  private _viewRef: ViewContainerRef;
  public get viewRef(): ViewContainerRef {
    return this._viewRef;
  }
  public set viewRef(v: ViewContainerRef) {
    this._viewRef = v;
  }

  constructor() {
    this._viewRef = null;
  }

  public raise<C>(popup: Type<C>, options?: FsPopupOptions): Observable<any> {
    try {
      if (!popup) throw new Error('No component defined');
      if (!this._viewRef) throw new Error('No viewRef defined');

      const overlay = this._viewRef.createComponent(FsPopupOverlayComponent);
      overlay.instance.options = options;
      overlay.instance.popup = popup;
      overlay.instance.close.subscribe((_) => overlay.destroy());
      overlay.instance.initialOverlay();

      document.body.appendChild(overlay.location.nativeElement);

      return overlay.instance.close;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
