import { Component, OnInit } from '@angular/core';
import { FSMiniLoaderService } from './fs-mini-loader.service';

@Component({
  selector: 'fs-mini-loader',
  templateUrl: './fs-mini-loader.component.html',
  styleUrls: ['./fs-mini-loader.component.scss'],
})
export class FSMiniLoaderComponent implements OnInit {
  constructor(public loaderService: FSMiniLoaderService) {}

  ngOnInit(): void {}
}
