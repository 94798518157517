export enum EventType {
  League = 1,
  Tournament = 2,
  Contest = 3,
}

export const getEventTypeName = (type: EventType): string => {
  switch (type) {
    case EventType.League:
      return 'League';
    case EventType.Tournament:
      return 'Tournament';
    case EventType.Contest:
      return 'Contest';
  }
};
