import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FSFormField } from './fs-form-field.model';

@Component({
  selector: 'fs-form-field',
  templateUrl: './fs-form-field.component.html',
  styleUrls: ['./fs-form-field.component.scss'],
})
export class FsFormFieldComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fsFormField') fsFormField: ElementRef;

  @Input('maxlength') maxlength: number;
  @Input('hideCounter') hideCounter: boolean;
  @Input('activeClickable') activeClickable: boolean;
  @Input('type') inputType: string;
  @Input('hideActions') hideActions: boolean;
  @Input('options') options: FSFormField;
  @Input('readOnly') readOnly: boolean;

  @Output('onClearValue') onClearValue = new EventEmitter<void>();

  innerHtml: HTMLElement;
  innerControlEl: HTMLElement;
  inputEl: HTMLInputElement;
  textareaEl: HTMLTextAreaElement;
  toggleSwitchEl: HTMLElement;

  innerText: string;

  get inputValue(): any {
    if (this.inputEl) return this.inputEl.value ?? '';
    else if (this.textareaEl) return this.textareaEl.value ?? '';
    else return '';
  }

  constructor() {
    this.inputType = 'text';
    this.hideActions = this.activeClickable = this.readOnly = false;
    this.hideCounter = true;
    this.maxlength = 0;
    this.options = new FSFormField({});
    this.innerText = '';
  }

  ngOnChanges(changes: any): void {
    if (changes && changes.readOnly) {
      if (this.readOnly) {
        this.deactiveInnerControls();
      } else {
        this.activeInnerControls();
      }
    }
  }

  ngAfterViewInit(): void {
    this.innerHtml = this.fsFormField.nativeElement;
    this.innerControlEl = this.innerHtml.querySelector(
      '.fs-field-wrapper > *:last-child'
    );

    if (this.innerControlEl) {
      const tagName = this.innerControlEl.tagName.toLowerCase();

      if (tagName === 'input') {
        this.inputEl = this.innerControlEl as HTMLInputElement;

        if (this.inputEl.type === 'number') {
          this.innerHtml
            .querySelector('.fs-field-wrapper')
            ?.classList.add('fs-field-numeric');
        } else {
          this.innerHtml
            .querySelector('.fs-field-wrapper')
            ?.classList.add('fs-field-input');
        }

        this.inputEl.addEventListener('keyup', (e) => {
          let current = e.target as HTMLInputElement;
          this.innerText = current.value;
        });

        this.inputEl.addEventListener('change', (e) => {
          let current = e.target as HTMLInputElement;
          this.innerText = current.value;
        });

        this.inputEl.addEventListener('focus', (e) => {
          let current = e.target as HTMLInputElement;
          this.innerText = current.value;
        });

        setTimeout(() => {
          this.innerText = this.inputEl.value;
        }, 10);
      } else if (tagName === 'textarea') {
        this.textareaEl = this.innerControlEl as HTMLTextAreaElement;

        this.hideActions = true;

        this.innerHtml
          .querySelector('.fs-field-wrapper')
          ?.classList.add('fs-field-input');

        this.textareaEl.addEventListener('keyup', (e) => {
          let current = e.target as HTMLTextAreaElement;
          this.innerText = current.value;
        });

        this.textareaEl.addEventListener('change', (e) => {
          let current = e.target as HTMLTextAreaElement;
          this.innerText = current.value;
        });

        this.textareaEl.addEventListener('focus', (e) => {
          let current = e.target as HTMLTextAreaElement;
          this.innerText = current.value;
        });

        setTimeout(() => {
          this.innerText = this.textareaEl.value;
        }, 10);
      } else if (tagName === 'fs-toggle-switch') {
        this.toggleSwitchEl = this.innerHtml.querySelector('.fs-toggle-switch');

        this.innerHtml
          .querySelector('.fs-field-wrapper')
          .classList.add('fs-field-toggle-switch');
      }
    }

    if (this.readOnly) {
      this.deactiveInnerControls();
    }
  }

  ngOnInit(): void {}

  onClearText(): void {
    this.inputEl.value = this.innerText = '';
    this.onClearValue.emit();
  }

  onVisibilityPassword(): void {
    if (this.inputEl.type !== 'password') {
      this.inputEl.type = 'password';
    } else {
      this.inputEl.type = 'text';
    }
  }

  deactiveInnerControls(): void {
    let controls = this.innerHtml?.querySelectorAll(
      '.action-field button, .fs-field-wrapper button, .fs-field-wrapper input, .fs-field-wrapper textarea'
    );

    controls?.forEach((control: HTMLElement) => {
      control.tabIndex = -1;
    });
  }

  activeInnerControls(): void {
    let controls = this.innerHtml?.querySelectorAll(
      '.action-field button, .fs-field-wrapper button, .fs-field-wrapper input, .fs-field-wrapper textarea'
    );

    controls?.forEach((control: HTMLElement) => {
      control.removeAttribute('tabindex');
    });
  }

  onClickDate(): void {
    this.inputEl.focus();
    this.inputEl.showPicker();
  }
}
