import { Component, OnInit } from '@angular/core';
import { PageFrameService } from './fs-page-frame.service';
import { FSMiniLoaderService } from '../fs-mini-loader/fs-mini-loader.service';

@Component({
  selector: 'fs-page-frame',
  templateUrl: './fs-page-frame.component.html',
  styleUrls: ['./fs-page-frame.component.scss'],
})
export class FsPageFrameComponent implements OnInit {
  constructor(public pageFrameService: PageFrameService,
    public loaderService: FSMiniLoaderService
  ) {}

  ngOnInit(): void {}

  onSearch(event: any): void {
    this.pageFrameService.setSearchInput(event.target.value);
  }

  isShowHeader(): boolean {
    return (
      this.pageFrameService.isActiveSearchBox ||
      this.pageFrameService.isActiveHistoryState
    );
  }
}
