import { SelectItem } from '../../fs-controls-shared.model';

export class FSRadioButtons {
  id?: number;
  title?: string;
  classes?: string;
  values?: SelectItem[];

  constructor(model: FSRadioButtons) {
    this.id = model.id || 0;
    this.title = model.title || '';
    this.classes = model.classes || '';
    this.values = model.values || new Array<SelectItem>();
  }
}
