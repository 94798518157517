import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsFormFieldComponent } from './fs-form-field.component';
import { FsTextCounterModule } from '../fs-text-counter/fs-text-counter.module';

@NgModule({
  declarations: [FsFormFieldComponent],
  imports: [CommonModule, FsTextCounterModule],
  exports: [FsFormFieldComponent],
})
export class FsFormFieldModule {}
