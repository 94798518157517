import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NavigationBarItem } from 'src/app/shared/models/application.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  navRouteToggle: boolean;
  currentRoute: string;
  private _urlParam: Subject<string> = new Subject<string>();

  navItems: NavigationBarItem[];

  constructor(private router: Router) {}

  public setUrlParam(param: string): void {
    window.localStorage.setItem(environment.navName, param);
    this._urlParam.next(param);
  }

  public removeUrlParam(): void {
    window.localStorage.removeItem(environment.navName);
  }

  public getUrlParam(): Observable<string> {
    return this._urlParam;
  }

  initialNavItemActive(): void {
    let routeName =
        window.localStorage.getItem(environment.navName) ??
        environment.defaultPage,
      url = window.location.pathname;

    if (url && url.length) {
      url = url.substring(1, url.length);
    }

    if (url?.indexOf(routeName) === -1) {
      routeName = environment.defaultPage;
    }

    let routeObj = this.navItems.find((f) => f.route === routeName);

    this.setNavItemActivate(routeName);

    if (routeObj?.urlParam?.length)
      routeName = `${routeObj.route}/${routeObj.name}`;
    else routeName = `/${routeObj.route}`;
  }

  setNavItemActivate(route: string): void {
    if (route && route.length) {
      for (let index = 0; index < this.navItems.length; index++) {
        let item = this.navItems[index];

        if (
          item &&
          item.route === route ||
          item.children?.find((f) => f.route === route)
        ) {
          item.activate = true;
        } else {
          item.activate = false;
        }
      }
    }
  }

  changeRoute(route: string, urlParam?: string, name?: string): void {
    let currentUrl = window.location.pathname;
    
    if (currentUrl === `/${route}`) return;
    if (urlParam?.length && currentUrl === `/${route}/${urlParam}`) return;

    let fullUrl = route;

    if (urlParam && urlParam.length) {
      this.setUrlParam(urlParam);
      fullUrl = `${route}/${urlParam}`;
    } else {
      this.setUrlParam(route);
      fullUrl = route;
    }

    this.setNavItemActivate(name);
    this.router.navigateByUrl(fullUrl);

    if (this.navRouteToggle) {
      this.toggleNavRoute();
    }
  }

  toggleNavRoute(): void {
    this.navRouteToggle = !this.navRouteToggle;
  }
}
