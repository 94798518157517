import { Injectable } from '@angular/core';
import { AnimationOptions } from './fs-animation.model';

@Injectable({
  providedIn: 'root',
})
export class FsAnimationService {
  constructor() {}

  public async animate(options: AnimationOptions): Promise<void> {
    let start = performance.now();
    return await new Promise((resolve) => {
      requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / options.duration;

        if (timeFraction > 1) timeFraction = 1;

        let progress = options.reverse
          ? options.timing(1 - timeFraction)
          : options.timing(timeFraction);

        options.draw(progress);

        //console.log(timeFraction);
        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        } else {
          resolve();
        }
      });
    });
  }

  public linear(timeFraction: number): number {
    return timeFraction;
  }

  public quad(timeFraction: number): number {
    return Math.pow(timeFraction, 2);
  }

  public circ(timeFraction: number): number {
    return 1 - Math.sin(Math.acos(timeFraction));
  }

  public back(x: number, timeFraction: number): number {
    return Math.pow(timeFraction, 2) * ((x + 1) * timeFraction - x);
  }

  public bounce(timeFraction: number): number {
    for (let a = 0, b = 1; 1; a += b, b /= 2) {
      if (timeFraction >= (7 - 4 * a) / 11) {
        return (
          -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2)
        );
      }
    }

    return 0;
  }

  public elastic(x: number, timeFraction: number): number {
    return (
      Math.pow(2, 10 * (timeFraction - 1)) *
      Math.cos(((20 * Math.PI * x) / 3) * timeFraction)
    );
  }

  public makeEaseOut(timing: Function): (timeFraction: number) => number {
    return (timeFraction: number): number => {
      return 1 - timing(1 - timeFraction);
    };
  }

  public bounceEaseOut(): (timeFraction: number) => number {
    return this.makeEaseOut(this.bounce);
  }

  public makeEaseInOut(timing: Function): (timeFraction: number) => number {
    return (timeFraction: number) => {
      if (timeFraction < 0.5) return timing(2 * timeFraction) / 2;
      else return (2 - timing(2 * (1 - timeFraction))) / 2;
    };
  }

  public bounceEaseInOut(): (timeFraction: number) => number {
    return this.makeEaseInOut(this.bounce);
  }

  public easeOutQuint(timeFraction: number): number {
    return 1 - Math.pow(1 - timeFraction, 5);
  }
}
