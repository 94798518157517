<div class="frame" [ngClass]="{'frameless': !pageFrameService.useFrameWrapper}">
    <div class="frame-bg" *ngIf="pageFrameService.useFrameWrapper && pageFrameService.backgroundVisible">
        <svg width="1227" height="982" viewBox="0 0 1227 982" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 4C0 1.79086 1.79086 0 4 0H448.808C449.913 0 450.968 0.456675 451.724 1.2618L458.94 8.9461C459.696 9.75122 460.751 10.2079 461.856 10.2079H765.144C766.248 10.2079 767.304 9.75122 768.06 8.9461L775.276 1.2618C776.032 0.456675 777.087 0 778.192 0H1196.51C1197.62 0 1198.67 0.456675 1199.43 1.2618L1225.92 29.4692C1226.61 30.2109 1227 31.19 1227 32.2074V978C1227 980.209 1225.21 982 1223 982H3.99997C1.79083 982 0 980.209 0 978V4Z"
                fill="black" [attr.fill-opacity]="pageFrameService.backgroundOpacity" />
        </svg>
    </div>

    <div class="frame-content">
        <div *ngIf="pageFrameService.useFrameWrapper && isShowHeader()" class="frame-header">
            <div class="start-slot">
                <div *ngIf="pageFrameService.isActiveHistoryState && pageFrameService.getCurrentHistory() && pageFrameService.getCurrentHistory().type !== 0"
                    class="history-state" [ngClass]="{'readonly-control': loaderService.loader}">
                    <a [class]="pageFrameService.historyIndex > 1 ? 'cursor-pointer' : 'readonly-control'"
                        (click)="pageFrameService.popHistory()">
                        <span *ngIf="pageFrameService.historyIndex > 1"><i></i></span>
                        {{ pageFrameService.getCurrentHistory().title }}
                    </a>
                </div>
                <ng-container [ngTemplateOutlet]="pageFrameService.startSlotTemplate"></ng-container>
            </div>

            <div *ngIf="pageFrameService.isActiveSearchBox" class="frame-search-box">
                <img src="./assets/icons/Magnifier.svg" alt="search">
                <fs-form-field [hideActions]="true" class="black-bg-input" [options]="{classes: 'frame-search-field'}">
                    <input id="search" type="text" (keyup)="onSearch($event)">
                </fs-form-field>
            </div>

            <div class="end-slot">
                <ng-container [ngTemplateOutlet]="pageFrameService.endSlotTemplate"></ng-container>
            </div>
        </div>

        <ng-content></ng-content>
    </div>
</div>