import { Component, OnInit } from '@angular/core';
import { FsToastService } from './fs-toast.service';

@Component({
  selector: 'fs-toast',
  templateUrl: './fs-toast.component.html',
  styleUrls: ['./fs-toast.component.scss'],
})
export class FsToastComponent implements OnInit {
  constructor(public toastService: FsToastService) {}

  ngOnInit(): void {}
}
