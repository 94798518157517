import { FsColorPaletteSymbols } from 'src/app/shared/modules/fs-controls-shared.model';
import {
  Directive,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Input,
} from '@angular/core';

export interface IColorDirective {
  setColor(): void;
}

@Directive({
  standalone: true,
  selector: '[fs-color]',
})
export class FsColorDirective implements OnInit, OnChanges, IColorDirective {
  @Input('color') color: FsColorPaletteSymbols;

  constructor(private element: ElementRef<HTMLElement | any>) {
    this.color = '';
  }

  setColor(): void {
    if (this.color?.length) {
      this.clearColors();

      switch (this.color) {
        case 'primary':
          this.element.nativeElement.classList.add('primary-clr');
          break;
        case 'success':
          this.element.nativeElement.classList.add('success-clr');
          break;
        case 'warning':
          this.element.nativeElement.classList.add('warning-clr');
          break;
        case 'danger':
          this.element.nativeElement.classList.add('danger-clr');
          break;
        case 'black':
          this.element.nativeElement.classList.add('black-clr');
          break;
        case 'light-gray':
          this.element.nativeElement.classList.add('lgray-clr');
          break;
        case 'white':
          this.element.nativeElement.classList.add('white-clr');
          break;
        default:
          break;
      }
    }
  }

  clearColors(): void {
    this.element.nativeElement.classList.remove(
      'primary-clr',
      'warning-clr',
      'success-clr',
      'danger-clr',
      'black-clr',
      'lgray-clr',
      'white-clr'
    );
  }

  ngOnInit(): void {
    this.setColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['color']) {
        this.setColor();
      }
    }
  }
}
