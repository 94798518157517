import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FSMiniLoaderComponent } from './fs-mini-loader.component';

@NgModule({
  declarations: [FSMiniLoaderComponent],
  imports: [CommonModule],
  exports: [FSMiniLoaderComponent],
})
export class FSMiniLoaderModule {}
