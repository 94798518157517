<main>
    <div class="main" [class]="pageFrameService.backgroundClass">
        <navigation></navigation>

        <div class="container">
            <fs-page-frame>
                <router-outlet *ngIf="authService.isAuthenticate !== authState.False"></router-outlet>
            </fs-page-frame>
        </div>
    </div>
</main>

<fs-mini-loader></fs-mini-loader>
<fs-toast></fs-toast>