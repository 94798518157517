import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/pages/auth/auth.service';
import {
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getCurrentSession()).pipe(
      switchMap((token: CognitoUserSession) => {
        // switchMap() is used instead of map().

        let t = token.getIdToken().getJwtToken();
        let headers = new HttpHeaders({
          //'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${t}`,
          'WWW-Authenticate': `Bearer ${t}`,
        });

        request = request.clone({
          headers: headers,
        });
        //console.log('Cloned', request);
        return next.handle(request);
      }),
      catchError((err) => {
        console.error('Error ', err);
        return next.handle(request);
      })
    );
  }
}

// let jwt = auth.accessToken.jwtToken;
// request = request.clone({
//   setHeaders: {
//     "Authorization": `Bearer ${jwt}`,
//   },
