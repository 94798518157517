import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  CustomValidator,
  RandomGenerator,
  clearStringFromWhiteSpace,
  convertToFlatArray,
  convertToInt,
  dateJSToInputDate,
  dateToShort,
  distinctArray,
  distinctObjArray,
  getUpperFirstChars,
  inputDateToDateJS,
  fullCheckNumber,
  manualCount,
  removeStorageItemsByExclude,
  subStringFromEnd,
  sumNumbers,
} from '../helper-functions';
import { EventType } from '../enums/event-type.enum';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private _apiBaymanagerPanelUrl: string;
  public get apiBaymanagerPanelUrl(): string {
    return this._apiBaymanagerPanelUrl;
  }

  constructor(private location: Location) {
    this._apiBaymanagerPanelUrl = environment.apiBaymanagerPanel;
  }

  public goBack(): void {
    this.location.back();
  }

  public formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public checkFormats(file: FileList, formatSelection: string[]): boolean {
    for (let index = 0; index < file.length; index++) {
      let templateImage = file[index];
      let formats = templateImage.name.split('.');
      let lastImageFormat = formats[formats.length - 1].toLowerCase();
      for (let index = 0; index < formatSelection.length; index++) {
        if (lastImageFormat === formatSelection[index]) {
          return true;
        }
      }
    }

    return false;
  }

  public moneySeparator(value: string): string {
    value += '';
    let x = value.split('.');
    let y = x[0];
    let z = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(y)) y = y.replace(rgx, '$1' + ',' + '$2');
    return y + z;
  }

  public SortBy(by: string | any, arr: any[], sorted: boolean): boolean {
    arr.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return sorted ? -1 : 1;
      }
      return 0;
    });
    return !sorted;
  }

  public getHoursFromTimeFormat(time: string): number {
    if (time && time.length) {
      let hours = parseInt(time.split(':')[0]) ?? 0;
      return hours;
    }
    return 0;
  }

  public getMinutesFromTimeFormat(time: string): number {
    if (time && time.length) {
      let minutes = parseInt(time.split(':')[1]) ?? 0;
      return minutes;
    }
    return 0;
  }

  public toHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key]);
    });
    return httpParams;
  }

  public getTime(date: Date): string {
    let h = new Date(date).getHours();
    let m = new Date(date).getMinutes();
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
  }

  public isValidDate(dateString: string | any): boolean {
    let reg = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    // First check for the pattern
    dateString = (dateString as String).replace(/-/g, '/');

    if (!reg.test(dateString)) {
      let numArr = (dateString as String).split('/'),
        newDate = [];
      for (let index = numArr.length - 1; index >= 0; index--)
        newDate.push(numArr[index]);
      dateString = newDate.toString().replace(/,/g, '/');
      if (!reg.test(dateString)) return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  public onClear(event: any, control: FormControl): void {
    if (event && event.pointerType) {
      if (event.pointerType === 'mouse' || event.pointerType === 'touch') {
        control.reset();
      }
    } else {
      control.reset();
    }
  }

  public set24TimeFormat(time: string): string {
    if (time && time.length) {
      let timeFormat: string = time
        .toLowerCase()
        .substring(time.length, time.length - 2);

      if (!timeFormat || !timeFormat.length) return time;

      let fixedTime = '';
      let hour = parseInt(time.split(':')[0]),
        min = parseInt(time.split(':')[1]);

      if (timeFormat === 'pm') {
        time = time.toLowerCase().replace('pm', '').trim();
        switch (hour) {
          case 1:
            fixedTime += 13;
            break;
          case 2:
            fixedTime += 14;
            break;
          case 3:
            fixedTime += 15;
            break;
          case 4:
            fixedTime += 16;
            break;
          case 5:
            fixedTime += 17;
            break;
          case 6:
            fixedTime += 18;
            break;
          case 7:
            fixedTime += 19;
            break;
          case 8:
            fixedTime += 20;
            break;
          case 9:
            fixedTime += 21;
            break;
          case 10:
            fixedTime += 22;
            break;
          case 11:
            fixedTime += 23;
            break;
          case 12:
            fixedTime += 12;
            break;
          default:
            break;
        }

        fixedTime += ':' + min;
        return fixedTime;
      } else if (timeFormat === 'am') {
        time = time.toLowerCase().replace('am', '').trim();
        if (hour == 12) hour = 0;
        return hour + ':' + min;
      }
    }

    return time;
  }

  public getDateOnly(date: Date): string {
    let year = date.getFullYear();
    let month = `${
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }`;
    let day = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
    return year + '-' + month + '-' + day;
  }

  public formatDate(date: any): any {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  //Date format for mobile browser
  public formatDate2(dateInput: string | any): Date {
    if (dateInput && dateInput.length) {
      dateInput = (dateInput as String).replace(/\//g, '-');

      let dateWS = dateInput;
      let date = dateWS.split(' ')[0].split('-');
      let time = dateWS.split(' ')[1]?.split(':') ?? null;

      return new Date(
        +date[0],
        +date[1] - 1,
        +date[2],
        time[0] && time[0].length ? +time[0] : null,
        time[1] && time[1].length ? +time[1] : null
      );
    }
    return null;
  }

  public searchData(
    value: string,
    destArr: any[],
    searchOnProperty: string[]
  ): any[] {
    if (value && value.length) {
      let tmpArr = [];
      value = value.toUpperCase();
      for (let index = 0; index < destArr.length; index++) {
        for (
          let propIndex = 0;
          propIndex < searchOnProperty.length;
          propIndex++
        ) {
          if (destArr[index][searchOnProperty[propIndex]]) {
            let prop = destArr[index][searchOnProperty[propIndex]];
            let find = prop.toUpperCase().indexOf(value);
            if (find > -1) {
              tmpArr.unshift(destArr[index]);
              break;
            }
          }
        }
      }
      return tmpArr;
    } else {
      return destArr;
    }
  }

  public capitalizeWord(value: string): string {
    if (!value?.length) return value;

    value = value.trim();
    if (value && value.length) {
      let strArr = value.split(' ');
      for (let index = 0; index < strArr.length; index++) {
        let ch = strArr[index][0];
        strArr[index] =
          ch.toUpperCase() + strArr[index].substring(1, strArr[index].length);
      }
      return strArr.toString().replace(/,/g, ' ');
    }

    return '';
  }

  public convertMinutesToFriendlyString(mins: number): string {
    if (mins >= 60 * 24) return '';

    let h = Math.floor(mins / 60),
      m = mins - h * 60;

    let strTime = '';
    let hasMinutes = m > 0;
    let hasHours = h > 0;

    if (hasHours)
      strTime += `${h} hr ${h > 1 ? 's' : ''} ${hasMinutes ? '&' : ''}`;

    if (hasMinutes) strTime += ` ${m} min${m > 1 ? 's' : ''}`;

    return strTime;
  }

  public convertJSDateToAPIFormat(date: Date): string {
    let day = date.getDate(),
      month = date.getMonth() + 1,
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds();
    return (
      day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second
    );
  }

  public screenSize(): number {
    return window.innerWidth;
  }

  public isMobileScreen(): boolean {
    return this.screenSize() <= environment.breakPointScreen.Medium;
  }

  public getDaysList(date: Date, range: number): number[] {
    let days: number[] = [];

    if (range > 0) {
      do {
        range--;

        let d = new Date(date);
        d = new Date(d.setDate(d.getDate() + range));
        days.unshift(d.getDate());
      } while (range !== 0);
    } else {
      while (range !== 0) {
        let d = new Date(date);
        d = new Date(d.setDate(d.getDate() + range));
        days.push(d.getDate());
        range++;
      }
    }

    return days;
  }

  public getDaysOfWeek(locale: string = 'default'): string[] {
    let today = new Date(),
      currentDay = today.getDay(),
      dayCounter = 0;

    today.setDate(today.getDate() - currentDay);

    let days: string[] = [];

    while (dayCounter < 7) {
      dayCounter++;
      days.push(today.toLocaleDateString(locale, { weekday: 'long' }));
      today.setDate(today.getDate() + 1);
    }

    return days;
  }

  public getMonthName(
    date: Date = new Date(),
    locale: string = 'default'
  ): string {
    return date.toLocaleString(locale, { month: 'long' });
  }

  public getMonthNameAndYear(
    date: Date = new Date(),
    locale: string = 'default'
  ): string {
    return date.toLocaleString(locale, { month: 'long', year: 'numeric' });
  }

  public assignObj(obj: any): any {
    let target: any = {};
    Object.assign(target, obj);
    return target;
  }

  public removeElement(
    src: Array<any>,
    value: any,
    key: string = 'id'
  ): Array<any> {
    return (src = src?.filter((f) => f[key] !== value));
  }

  public removeElementByIndex(src: Array<any>, index: number): Array<any> {
    return (src = src?.filter((f, i) => i !== index));
  }

  public findBiggerDate(date1: Date, date2: Date): number {
    if (date1 > date2) {
      return 1;
    } else if (date1 < date2) {
      return 2;
    } else {
      return 0;
    }
  }

  // public setEventDateStatus(
  //   start: Date | string,
  //   end: Date | string
  // ): EventStatus {
  //   if (typeof start === 'string') start = new Date(start);
  //   if (typeof end === 'string') end = new Date(end);

  //   let now = new Date();

  //   start = convertDateUTCToLocal(start);
  //   end = convertDateUTCToLocal(end);
  //   now = convertDateUTCToLocal(now);

  //   if (end < now) return EventStatus.Completed;
  //   else if (end >= now && start <= now) return EventStatus.InProgress;
  //   else return EventStatus.NotStarted;
  // }

  public playerScoreLimit(length: number): number {
    if (length < 18) return 9;
    else return 18;
  }

  public calcScorecardPar(score: number, par: number): string {
    if (score === par + 2) {
      return 'fs-stack-list-cell-2bs';
    } else if (score === par + 1) {
      return 'fs-stack-list-cell-bs';
    } else if (score === par - 1) {
      return 'fs-stack-list-cell-rc';
    } else if (score === par - 2) {
      return 'fs-stack-list-cell-2rc';
    } else {
      return '';
    }
  }

  public groupBy<T>(source: T[], key: string = 'id'): object {
    return source.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public sortArray<T>(source: T[], key: string = 'id'): void {
    source.sort((a, b) => {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    });
  }

  public strUpperFirst(source: string): string {
    return source
      ?.trim()
      ?.split(' ')
      ?.map((m) => m.replace(/^./, m[0].toUpperCase()))
      ?.join(' ');
  }

  public stringIsNullOrEmpty(source: string): boolean {
    if (typeof source !== 'undefined' && source?.length) return false;
    return true;
  }

  public generateEventToken(eventType: EventType, name?: string): string {
    let token = name[0] ?? '';
    // let timeConverted = new Date()
    //   .toLocaleString('en-US', {
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: '2-digit',
    //   })
    //   .replace('/', '');

    //token += `-${eventType === EventType.League ? 'L' : 'T'}-${timeConverted}-`;
    token += `-${eventType === EventType.League ? 'L' : 'T'}-`;
    token += RandomGenerator.stringId(3);

    return token.toUpperCase();
  }

  fixedDateOn00_00(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  }

  fixedDateOn23_59(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(0);

    return date;
  }

  //Constant helper functions as service functions
  public sumNumbers(
    source: number[],
    from: number = 0,
    to: number = source.length
  ): number {
    return sumNumbers(source, from, to);
  }

  public distinctArray<T>(source: T[]): T[] {
    return distinctArray<T>(source);
  }

  public distinctObjArray<T>(source: T[], key: string = 'id'): T[] {
    return distinctObjArray<T>(source, key);
  }

  public dateJSToInputDate(date: Date = new Date()): string {
    return dateJSToInputDate(date);
  }

  public inputDateToDateJS(date: string): Date {
    return inputDateToDateJS(date);
  }

  public subStringFromEnd(source: string, length: number): string {
    return subStringFromEnd(source, length);
  }

  public getUpperFirstChars(source: string, seperator: string = ' '): string {
    return getUpperFirstChars(source, seperator);
  }

  public dateToShort(date: Date): string {
    return dateToShort(date);
  }

  public removeStorageItemsByExclude(excludeKeys?: string[]): void {
    return removeStorageItemsByExclude(excludeKeys);
  }

  public printRandomNumber(
    digits: number = 100,
    inNegRange: boolean = false
  ): number {
    return RandomGenerator.number(digits, inNegRange);
  }

  public convertToInt(value: string): number {
    return convertToInt(value);
  }

  public convertToFlatArray<T>(source: Array<T>): Array<T> {
    return convertToFlatArray(source);
  }

  public clearStringFromWhiteSpace(value: string): string {
    return clearStringFromWhiteSpace(value);
  }

  public fullCheckNumber(value: number): boolean {
    return fullCheckNumber(value);
  }

  public phoneValidation(
    event: KeyboardEvent,
    phoneControl: FormControl<string>
  ): void {
    CustomValidator.phoneValidation(event, phoneControl);
  }

  public manualCount(
    dir: 'up' | 'down',
    value: number,
    min: number = 0,
    max: number = 100
  ): number {
    return manualCount(dir, value, min, max);
  }
}
