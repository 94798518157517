import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsTextCounterComponent } from './fs-text-counter.component';
import { FsColorDirective } from '../../fs-directives/fs-color.directive';

@NgModule({
  declarations: [FsTextCounterComponent],
  imports: [CommonModule, FsColorDirective],
  exports: [FsTextCounterComponent],
})
export class FsTextCounterModule {}
