export class FSLayerSelectorItem {
  id?: number;
  value?: string;
  label?: string;
  background?: string;
  color?: string;

  constructor(model: FSLayerSelectorItem) {
    this.id = model.id || 1;
    this.value = model.value || '';
    this.label = model.label || 'NO';
    this.background = model.background || '#000000';
    this.color = model.color || '#ffffff';
  }
}

export class FSLayerSelector {
  id?: number;
  label?: string;
  name?: string;
  layerSize?: number;
  values?: FSLayerSelectorItem[];

  constructor(model: FSLayerSelector) {
    this.id = model.id || 0;
    this.label = model.label || '';
    this.name = model.name || '';
    this.layerSize = model.layerSize || 46;
    this.values = model.values || new Array<FSLayerSelectorItem>();
  }
}
