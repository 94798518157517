import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsInfoDialogComponent } from './fs-info-dialog.component';

@NgModule({
  declarations: [FsInfoDialogComponent],
  imports: [CommonModule],
  exports: [FsInfoDialogComponent],
})
export class FsInfoDialogModule {}
