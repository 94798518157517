import { TemplateRef } from '@angular/core';

export class FsSelectorFieldOptions {
  id?: any;
  itemPerView?: number;
  itemWidth?: number;
  itemHeight?: number;
  hideNav?: boolean;

  constructor(model: FsSelectorFieldOptions) {
    this.id = model.id || (null as any);
    this.itemPerView = model.itemPerView || 3;
    this.itemWidth = model.itemWidth || 100;
    this.itemHeight = model.itemHeight || 53;
    this.hideNav = model.hideNav || false;
  }
}

export class FsSelectorFieldDataSource<V = any> {
  direction?: 'column' | 'row';
  values?: Array<FsSelectorFieldValue<V>>;

  constructor(model: FsSelectorFieldDataSource<V>) {
    this.direction = model.direction || 'row';
    this.values = model.values || new Array<FsSelectorFieldValue<V>>();
  }
}

export class FsSelectorFieldValue<V = any> {
  index?: number;
  name?: string;
  value?: V;
  classes?: string;
  isActive?: boolean;
  selected?: boolean;
  template?: TemplateRef<any>;
  childs?: FsSelectorFieldDataSource<V>;
  hideNav?: boolean;

  constructor(model: FsSelectorFieldValue<V>) {
    this.index = model.index || 0;
    this.name = model.name || '';
    this.value = model.value;
    this.classes = model.classes || '';
    this.isActive = model.isActive || true;
    this.selected = model.selected || false;
    this.template = model.template || (null as any);
    this.childs = model.childs || (null as any);
    this.hideNav = model.hideNav || false;
  }
}
