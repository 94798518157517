export enum EventFormat {
  Stroke = 1,
  Match = 2,
  BestBall = 3,
  Scramble = 4,
}

export const getEventFormatName = (format: EventFormat): string => {
  switch (format) {
    case EventFormat.Stroke:
      return 'Stroke';
    case EventFormat.Match:
      return 'Match';
    case EventFormat.BestBall:
      return 'Best Ball';
    case EventFormat.Scramble:
      return 'Scramble';
  }
};
