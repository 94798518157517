import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './shared/services/http.service';
import { IndexedDBService } from './shared/services/IndexedDB.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HelperService } from './shared/services/helper.service';
import { AuthService } from './pages/auth/auth.service';
import { NavigationService } from './components/navigation/navigation.service';
import { FsPageFrameModule } from './shared/modules/fs-page-frame/fs-page-frame.module';
import { FSMiniLoaderModule } from './shared/modules/fs-mini-loader/fs-mini-loader.module';
import { FSMiniLoaderService } from './shared/modules/fs-mini-loader/fs-mini-loader.service';
import { GlobalService } from './shared/services/global.service';
import { AuthGuard } from './pages/auth/auth.guard';
import { HttpInterceptorProviders } from './shared/services/interceptor-provider';
import { FsToastModule } from './shared/modules/fs-popups/fs-toast/fs-toast.module';
import { FsAlignmentDirectives } from './shared/modules/fs-directives/fs-alignment/alignment-import.directives';
import { LoggerService } from './shared/services/logger.service';
import { FsPopupOverlayModule } from './shared/modules/fs-popups/fs-popup-overlay/fs-popup-overlay.module';
import { FsInfoDialogModule } from './shared/modules/fs-popups/fs-info-dialog/fs-info-dialog.module';

@NgModule({
  declarations: [AppComponent, NavigationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FsPageFrameModule,
    FSMiniLoaderModule,
    FsToastModule,
    FsAlignmentDirectives,
    FsPopupOverlayModule,
    FsInfoDialogModule
  ],
  providers: [
    HttpInterceptorProviders,
    NavigationService,
    HelperService,
    HttpService,
    IndexedDBService,
    AuthService,
    FSMiniLoaderService,
    GlobalService,
    AuthGuard,
    LoggerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
