export class AnimationOptions {
  duration: number;
  timing: Function;
  draw: Function;
  reverse?: boolean;
  stop?: number;

  constructor(model: AnimationOptions) {
    this.duration = model.duration || 0;
    this.timing = model.timing || (null as any);
    this.draw = model.draw || (null as any);
    this.reverse = model.reverse || false;
    this.stop = model.stop || 1;
  }
}
