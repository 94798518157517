import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsPopupOverlayService } from './fs-popup-overlay.service';
import { FsPopupOverlayComponent } from './fs-popup-overlay.component';

@NgModule({
  declarations: [FsPopupOverlayComponent],
  imports: [CommonModule],
  exports: [FsPopupOverlayComponent],
  providers: [FsPopupOverlayService],
})
export class FsPopupOverlayModule {}
