export class FSToastModel {
  id?: number;
  title?: string;
  description?: string;
  duration?: number;

  constructor(model: FSToastModel) {
    this.id = model.id || 0;
    this.title = model.title || '';
    this.description = model.description || '';
    this.duration = model.duration || 2000;
  }
}
