import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsToastComponent } from './fs-toast.component';
import { FsToastService } from './fs-toast.service';

@NgModule({
  declarations: [FsToastComponent],
  imports: [CommonModule],
  exports: [FsToastComponent],
  providers: [FsToastService],
})
export class FsToastModule {}
