// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //Endpoints
  apiLeagueServer: 'https://devcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://devlauncher.fsgapi.net/api/fs',
  apiBaymanagerPanel: 'https://devbaymanager.fsgapi.net/',
  hostName: 'devcompeteadmin.fullswingapps.com',

  //Variables
  defaultPage: 'home',
  navName: '_fs_nav_name',

  //Objects
  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  //Ploicy groups
  companyAdmin: 'companyadmin',
  competeAdmin: 'competeadmin',
  bayManagerAdmin: 'baymanageradmin',
  competePlayer: 'competeplayer',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
