import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiUrl } from '../enums/api-url.enum';
import { buildQueryParams } from '../helper-functions';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _url: string;

  public get getUrl(): string {
    return this._url;
  }

  constructor(private httpClient: HttpClient) {
    this._url = environment.apiLeagueServer;
  }

  public setApiUrl(api: ApiUrl): void {
    if (api === ApiUrl.FromBaymanager)
      this._url = environment.apiBaymanagerServer;
    else this._url = environment.apiLeagueServer;
  }

  public getItemByQuery<T>(
    api: ApiUrl,
    path: string,
    queryModel: any
  ): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<T>(url, {
        //headers: headers,
        params: buildQueryParams(queryModel),
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public postItemByQuery<T>(
    api: ApiUrl,
    path: string,
    queryModel: any,
    data: any = null
  ): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<T>(url, data, {
        //headers: headers,
        params: buildQueryParams(queryModel),
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getItems<T>(api: ApiUrl, path: string): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.get<T>(url).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public getItemById<T>(api: ApiUrl, id: any, path: string): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path + `\\${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.get<T>(url).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public createItem<T>(api: ApiUrl, data: any, path: string): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<T>(url, data).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public updateItem<T>(api: ApiUrl, data: any, path: string): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.put<T>(url, data).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public removeItem<T>(api: ApiUrl, data: any, path: string): Observable<T> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<T>(url, {
        params: {
          ...data,
        },
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public removeItemFromBody(
    api: ApiUrl,
    data: any,
    path: string
  ): Observable<any> {
    this.setApiUrl(api);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.delete<any>(url, { body: data }).pipe((res) => {
      const p = res || null;
      return p;
    });
  }
}
