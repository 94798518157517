export enum PageHistoryType {
  None = 0,
  ChangePage = 1,
  ChangeTab = 2,
  ChangeState = 3,
}

export class FSPageHistoryOption {
  id?: number;
  type?: PageHistoryType;
  route?: string;
  title?: string;

  constructor(model: FSPageHistoryOption) {
    this.id = model.id || 0;
    this.type = model.type || PageHistoryType.None;
    this.route = model.route || '';
    this.title = model.title || '';
  }
}
