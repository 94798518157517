<div class="nav-bar">
    <div class="nav-bar-bg-wrapper">
        <img #navBGContent src="/assets/svgs/top-bar.svg" alt="navigation image">
        <svg width="204" height="36" viewBox="0 0 204 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8485_47249)">
                <path
                    d="M60.9559 26.9091C57.4798 26.9091 56.3625 26.1818 56.3625 23.0303V15.7576C56.3625 12.6061 57.3557 11.8788 60.9559 11.8788H73.743V15.6364H62.0732C61.7008 15.6364 61.7008 15.7576 61.7008 16V22.6666C61.7008 22.9091 61.825 23.0303 62.0732 23.0303H73.743V26.9091H60.9559Z"
                    fill="white" />
                <path
                    d="M80.8194 27.0303C77.3433 27.0303 76.2259 26.303 76.2259 23.1515V15.7576C76.2259 12.6061 77.3433 11.8788 80.8194 11.8788H91.6201C95.0962 11.8788 96.2135 12.6061 96.2135 15.7576V23.1515C96.2135 26.303 95.2204 27.0303 91.6201 27.0303H80.8194ZM90.8752 15.8788C90.8752 15.6364 90.7511 15.5151 90.5028 15.5151H82.0608C81.6884 15.5151 81.5643 15.6364 81.5643 15.8788V22.7879C81.5643 23.0303 81.6884 23.1515 82.0608 23.1515H90.5028C90.8752 23.1515 90.8752 23.0303 90.8752 22.7879V15.8788Z"
                    fill="white" />
                <path
                    d="M115.456 26.9091V22.303C115.456 20.7273 115.58 18.4242 115.58 17.5758H115.456C115.084 18.1818 114.339 19.3939 113.842 20L109.994 25.3333H109.125L105.276 20C104.78 19.3939 104.035 18.0606 103.786 17.5758H103.662C103.786 18.5455 103.786 20.7273 103.786 22.303V26.9091H98.6965V11.8788H104.407L107.387 16.3636C108.007 17.2121 109.125 19.0303 109.497 19.6363C109.87 19.1515 111.111 17.2121 111.608 16.3636L114.711 11.8788H120.422V26.9091H115.456Z"
                    fill="white" />
                <path
                    d="M128.243 22.303V27.0303H123.029V12H136.065C139.541 12 140.534 12.8485 140.534 15.3939V18.7879C140.534 21.4545 139.665 22.303 136.189 22.303H128.243ZM135.444 15.8788C135.444 15.6364 135.32 15.5151 135.071 15.5151H128.243V18.6667H135.071C135.444 18.6667 135.444 18.5455 135.444 18.303V15.8788Z"
                    fill="white" />
                <path
                    d="M143.141 26.9091V11.8788H160.149V15.5151H148.355V17.5758H160.149V20.9697H148.355V23.2727H160.149V26.9091H143.141Z"
                    fill="white" />
                <path d="M168.839 26.9091V15.6364H162.508V11.8788H180.385V15.6364H174.053V26.9091H168.839Z"
                    fill="white" />
                <path
                    d="M182.992 26.9091V11.8788H200V15.5151H188.082V17.5758H200V20.9697H188.082V23.2727H200V26.9091H182.992Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.953 8.2418H56.3158V7.02968H199.953V8.2418Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.953 31.9994H56.3158V30.7873H199.953V31.9994Z"
                    fill="white" />
                <path d="M5.95904 14.7879V24.7273H0V0H26.4432V4.48483H5.95904V10.4243H26.4432V14.7879H5.95904Z"
                    fill="white" />
                <path
                    d="M21.2291 31.8788V27.2727H42.334C42.9547 27.2727 43.203 27.1515 43.203 26.5455V21.9394C43.203 21.3333 42.9547 21.2121 42.334 21.2121H27.5605C22.7188 21.2121 21.2291 20 21.2291 16V12.3636C21.2291 8.36362 22.7188 7.15151 27.5605 7.15151H49.162V11.3939H27.933C27.3122 11.3939 27.0639 11.5152 27.0639 12.1212V16.1212C27.0639 16.7273 27.3122 16.8485 27.933 16.8485H42.8305C47.6723 16.8485 49.162 18.0606 49.162 21.8182V26.6667C49.162 30.6667 47.6723 31.8788 42.8305 31.8788H21.2291Z"
                    fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_8485_47249" x="0" y="0" width="204" height="35.999" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8485_47249" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8485_47249" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>

    <nav *ngIf="authService.isAuthenticate === 1" class="nav-container">
        <ul class="nav-items-wrapper">
            <li *ngFor="let nav of navService.navItems">
                <a [class]="nav.activate ? 'active' : ' '"
                    (click)="navService.changeRoute(nav.route, nav.urlParam, nav.name)">{{
                    nav.view }}</a>
                <div *ngIf="nav.activate" class="active-border"></div>
            </li>
        </ul>

        <div class="access-profile">
            <div class="drop-list-wrapper">
                <img src="./assets/icons/Staff-48.svg">
                <div class="drop-list-header">
                    <div>
                        <span>Account</span>
                        <i
                            [ngStyle]="{ 'background-image': isActiveProfileBody ? 'url(./assets/icons/Arrow-Up.svg)' : 'url(./assets/icons/Arrow-Down.svg)' }"></i>
                    </div>
                </div>
                <a class="mask-link" (click)="onExpandProfile()"></a>
            </div>
            <ul *ngIf="isActiveProfileBody" class="drop-list-body">
                <li><a (click)="onSettings()">Settings</a>
                </li>
                <li><a (click)="onExit()">Exit <i></i></a></li>
            </ul>
        </div>
    </nav>
</div>
<div *ngIf="isActiveProfileBody" class="fs-list-selector-mask" (click)="onExpandProfile()"></div>