import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { lastValueFrom } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { ApiUrl } from 'src/app/shared/enums/api-url.enum';
import { environment } from 'src/environments/environment';
import { AuthState } from 'src/app/shared/enums/auth-state.enum';
import {
  ChangePasswordObject,
  UserResponse,
} from 'src/app/shared/models/user.model';
import { Router } from '@angular/router';
import { removeStorageItemsByExclude } from 'src/app/shared/helper-functions';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { AWSErrorHandler, AWSResponse, AWSResponseType } from './auth.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticate: AuthState;
  public get isAuthenticate(): AuthState {
    return this._isAuthenticate;
  }
  public set isAuthenticate(v: AuthState) {
    this._isAuthenticate = v;
  }

  private _changePasswordUrlObject: ChangePasswordObject = null;
  public get changePasswordUrlObject(): ChangePasswordObject {
    return this._changePasswordUrlObject;
  }
  public set changePasswordUrlObject(v: ChangePasswordObject) {
    this._changePasswordUrlObject = v;
  }

  constructor(
    private httpService: HttpService,
    private router: Router,
    private loggerService: LoggerService
  ) {
    this._isAuthenticate = AuthState.False;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async isTokenValid(): Promise<boolean> {
    try {
      var isValid = (await Auth.currentSession()).isValid();
      return isValid;
    } catch (err) {
      return false;
    }
  }

  async refreshToken(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        this.tokenRefreshed
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  async getTokenExpiration(): Promise<Date> {
    try {
      const user = await this.getCurrentUser();
      var unix_timestamp = user
        .getSignInUserSession()
        .getIdToken()
        .getExpiration();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date(unix_timestamp * 1000);
      return date;
    } catch (err) {
      return null;
    }
  }

  async getUserId(): Promise<string> {
    try {
      const attr = await this.getUserInfo();
      var idToken = attr.username;
      return idToken;
    } catch (err) {
      //console.error(err);
      return null;
    }
  }

  async getIdToken(): Promise<CognitoIdToken> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      return idToken;
    } catch (err) {
      return null;
    }
  }

  async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  async getCurrentUser(): Promise<CognitoUser> {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      return null;
    }
  }

  async getUserInfo(): Promise<any> {
    try {
      const res = await Auth.currentUserInfo();
      return res;
    } catch (err) {
      //console.error('Current user info failed to fetch', err);
      return err;
    }
  }

  async getUserAttributebyKey(key: string): Promise<string> {
    try {
      let currentUserInfo = await Auth.currentUserInfo();
      let value = currentUserInfo.attributes[key];
      return value;
    } catch (err) {
      console.error('error fetching user info: ', err);
      return null;
    }
  }

  async updateCognitoUser(name: string): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      const result = await Auth.updateUserAttributes(user, {
        name,
      });

      if (result?.length && result.toLocaleLowerCase() === 'success')
        return true;

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async signIn(userName: string, password: string): Promise<any> {
    try {
      const user = await Auth.signIn(userName, password);

      if (
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        return -1;
      } else if (user && user.attributes && user.attributes.sub?.length) {
        // user exists
        return 1;
      } else {
        // user doenst exist
        return -2;
      }
    } catch (err) {
      console.error('Current user info failed to fetch', err);
      return 0;
    }
  }

  async completeNewPassword(userName: string, code: string, password: string) {
    return Auth.signIn(userName, code)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          return Auth.completeNewPassword(
            user, // the Cognito User Object
            password // the new password
          )
            .then((res) => {
              return true;
            })
            .catch((e) => {
              console.error(e);
              return false;
            });
        } else {
          // at this time the user is logged in if no MFA required
          return true;
          // other situations
        }
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const res = await Auth.changePassword(
        await this.getCurrentUser(),
        oldPassword,
        newPassword
      );
      return res;
    } catch (err) {
      //console.error('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPassword(userName: string) {
    try {
      const res = await Auth.forgotPassword(userName);
      return res;
    } catch (err) {
      //console.error('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPasswordSubmit(
    userName: string,
    confirmationCode: string,
    password: string
  ): Promise<AWSResponse> {
    try {
      const response = await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        password
      );

      if (typeof response === 'string') {
        if (response === 'SUCCESS')
          return new AWSResponse({
            type: AWSResponseType.Success,
          });
      }

      return new AWSResponse({
        type: AWSResponseType.Unknown,
        message: response,
      });
    } catch (error) {
      return AWSErrorHandler.result(error);
    }
  }

  async signOut(): Promise<void> {
    try {
      await Auth.signOut({ global: false });
    } catch (error) {
      console.error(error);
    }
  }

  public async getUserData(): Promise<UserResponse> {
    try {
      const cognitoId = await this.getUserId();

      if (cognitoId) {
        //New endpoint
        const user = await lastValueFrom(
          this.httpService.getItemById<UserResponse>(
            ApiUrl.FromBaymanager,
            cognitoId,
            '/CompeteAdmin/my-profile'
          )
        );

        this.loggerService.printObject('Admin Data', user);

        // const user = await lastValueFrom(
        //   this.httpService.getItemById<UserResponse>(
        //     ApiUrl.FromBaymanager,
        //     cognitoId,
        //     '/Users/admin-league'
        //   )
        // );

        if (user) return user;
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private tokenRefreshed(e, r) {
    var idToken = r.getIdToken();
  }

  public async isCompetePlayer(): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      for (let index = 0; index < groups.length; index++) {
        if (groups[index] === 'competeplayer') return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async isAdmin(admin: string = null): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      if (admin?.length) return groups.find((f) => f === admin) ? true : false;

      return groups.filter((f) => f.search('admin') > 0)?.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getCognitoGroups(): Promise<Array<string>> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken()?.payload['cognito:groups'];

      return groups;
    } catch (error) {
      return null;
    }
  }

  public async isAuthenticatedCognitoGroups(): Promise<boolean> {
    try {
      const groups = await this.getCognitoGroups();
      //console.log('Groups : ', groups);

      if (groups?.length) {
        const accounts = groups.filter((f) => f.search('admin') > 0);

        if (accounts?.length) {
          const admins = accounts.filter(
            (f) =>
              f === environment.companyAdmin || f === environment.competeAdmin
          );

          if (admins?.length) {
            return true;
          }
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  public async unautorized(excludeStorageKeys?: string[]): Promise<void> {
    try {
      await this.signOut();
    } catch (error) {
      console.error(error);
    } finally {
      this._isAuthenticate = AuthState.UseAuth;
      removeStorageItemsByExclude(excludeStorageKeys);
      this.router.navigate(['login']);
    }
  }
}
